import './src/styles/styles.css'
import './src/styles/custom.css'
import './src/styles/mobile-styles.min.css'
import './src/styles/tablet-styles.min.css'
if (window.innerWidth > 992) {
  require('./src/styles/desktop-lg-styles.min.css')
}
if (window.innerWidth > 1200) {
  require('./src/styles/desktop-xl-styles.min.css')
}
